import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {
  useBasketStore,
  useCustomerStore,
  useGlobalStore,
  useOrderStore,
  useProductStore,
} from '../../store/hooks/useStore'
import {Box, Button, Flex, Stack, Text} from '../../vanilla'
import {HeartFilledIcon, HeartIcon} from '../icons'
import {Link} from '../link'
import {PDPBadgeAsset, TopRightProductBadge} from '../cms/product-badges'
import {ReviewStars} from '../review-stars'
import {MultiBuyPromo, MultiBuyPromoOfferMessage, NearPromo} from '../product-detail/product-promo'
import useWishlistItem from '../../store/hooks/useWishlistItem'
import {useAlcoholRestrictions} from '../../hooks/use-alcohol-restrictions'
import {useItemPrice} from '../algolia/util/useItemPrice'
import {Hit, ProductModel} from '../../store/ProductStore'
import useEinstein from '../../store/hooks/useEinstein'
import {Price} from '../price'
import UpdateCartItemQuantity from '../update-cart-quantity'
import {transformHit} from '../algolia/util/transformHit'
import {ATBButtonPopover} from '../add-to-basket-button-popover'
import {useBasketItem} from '../../store/hooks/useBasketItem'
import {useBookDeliveryPopover} from '../../contexts'
import {useAddToCartTracking} from '../../analytics/click-event-tracking'
import {CART_LOCATION} from '../../analytics/utils'
import {useSlotExpiration} from '../../store/hooks/useSlotExpiration'
import {formatProductName} from '../../utils/utils'
import {ProductImage} from '../product-detail/product-image'

const ProductTileContainer: React.FC<
  Pick<ProductTileRegularProps, 'className' | 'isPLPTile' | 'showAddToBasket' | 'hideOffer'> & {
    product: Record<string, any>
  }
> = ({product, className, isPLPTile, hideOffer}) => {
  const basketStore = useBasketStore()
  const {editMode} = useOrderStore()
  const globalStore = useGlobalStore()

  const {isReservationExpired, handleEditReservationExpired} = useSlotExpiration()
  const {sendViewProduct} = useEinstein()
  const {displayPrice, promotionPrice} = useItemPrice(product as ProductModel)
  const {handleRemoveWishlistItem, isInWishlist, handleAddWishlistItem} = useWishlistItem(
    product.id,
  )
  const hit = transformHit(product as Hit) as Hit
  const {checkIfProductAlcohol, isCurrentSlotAlcoholRestricted} = useAlcoholRestrictions()
  const isAlcohol = checkIfProductAlcohol(hit)
  const disabledProductBecauseOfRestriction = isAlcohol && isCurrentSlotAlcoholRestricted

  // @todo format the algolia data to be consistent across PLP and PDP - happens in price component too
  const img = product.image_groups?.[0]?.images?.[0] || product.imageGroups?.[0]?.images?.[0]
  const {quantity} = useBasketItem(hit.id)
  const [clickedQuantity, setClickedQuantity] = useState(0)
  const {showBookDeliveryPopover} = useBookDeliveryPopover()
  const sendAddToCartData = useAddToCartTracking(basketStore.basket)
  const {getApplicablePromotions} = useCustomerStore()
  const {multiBuyPromo, nearPromo} = getApplicablePromotions(product.productPromotions)

  useEffect(() => {
    setClickedQuantity(quantity)
  }, [basketStore.count])

  const addToBasket = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (editMode && isReservationExpired()) {
      // If in edit mode and slot has expired send to order history and don't add to basket
      handleEditReservationExpired()
    } else if (!basketStore.canAddItems) {
      showBookDeliveryPopover({
        clickedHitId: hit.id,
        bookDeliveryProps: {
          product: hit,
        },
      })
    } else {
      basketStore.addProductToUpdate({product: hit, quantity: 1})
      setClickedQuantity(1)
    }
  }

  const productLink = `/p/${formatProductName(product?.name)}/${product?.id}.html`

  // Sets current custom site preferences for three badges in state
  const [pdpTopRightTileBadgeEnabled, setPdpTopRightTileBadgeEnabled] = useState(false)
  const [pdpMiddleRightTileBadgeEnabled, setPdpMiddleRightTileBadgeEnabled] = useState(false)
  const [pdpBottomRightTileBadgeEnabled, setPdpBottomRightTileBadgeEnabled] = useState(false)

 // Updates state with current custom site preferences for three badges
 useEffect(() => {
  setPdpTopRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.topRightBadgeCustomPreference as boolean)
  setPdpMiddleRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.middleRightBadgeCustomPreference as boolean)
  setPdpBottomRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.bottomRightBadgeCustomPreference as boolean)
}, [])

  return (
    <Box position="relative" className={className}>
      <Button
        variant="unstyled"
        position="absolute"
        right="0px"
        top="0px"
        zIndex="docked"
        data-test-selector="favourite-icon"
        onClick={() => (isInWishlist ? handleRemoveWishlistItem() : handleAddWishlistItem())}
      >
        {isInWishlist ? (
          <HeartFilledIcon color="accent0" boxSize="20px" />
        ) : (
          <HeartIcon color={{default: 'black', hover: 'accent0'}} boxSize="20px" />
        )}
      </Button>

      <Flex
        flexDirection={[isPLPTile ? 'row' : 'column', 'column']}
        flexWrap="wrap"
        align="center"
        paddingX="12px"
        paddingTop="12px"
        data-test-selector="product-list-item"
        paddingBottom="20px"
        bg="white"
        height="full"
      >
        <Box
          as={Link}
          href={productLink}
          ratio="1"
          width="full"
          style={{
            maxWidth: 160,
            flexBasis: 148,
            ...(disabledProductBecauseOfRestriction && {
              pointerEvents: 'none',
            }),
          }}
          marginTop={['0px', '32px']}
          flexShrink="0"
          position="relative"
          onClick={() => {
            sendViewProduct({product: {id: product.id}})
          }}
        >
          {disabledProductBecauseOfRestriction ? (
            <Box
              alignItems="center"
              display="flex"
              justifyItems="center"
              position="absolute"
              style={{inset: 0}}
            >
              <Box
                alignItems="center"
                borderRadius="base"
                display="flex"
                marginX="16px"
                paddingX="8px"
                paddingY="16px"
                style={{backgroundColor: 'rgba(220,220,220,0.85)'}}
              >
                <Text textAlign="center" variant="text3">
                  Alcohol is unavailable at this time
                </Text>
              </Box>
            </Box>
          ) : null}
          {pdpTopRightTileBadgeEnabled && product.pdpTopRightTileBadge ? (
            <TopRightProductBadge
              badgeKey={product.pdpTopRightTileBadge}
              right={-5}
              top={-5}
              zIndex="badge"
            />
          ) : null}
          {pdpMiddleRightTileBadgeEnabled && product.pdpMiddleRightTileBadge ? (
            <TopRightProductBadge
              badgeKey={product.pdpMiddleRightTileBadge}
              right={-5}
              top={32}
              zIndex="badge"
            />
          ) : null}
          {pdpBottomRightTileBadgeEnabled && product.pdpBottomRightTileBadge ? (
            <TopRightProductBadge
              badgeKey={product.pdpBottomRightTileBadge}
              right={-5}
              top={69}
              zIndex="badge"
            />
          ) : null}
          <ProductImage 
            image={img}
            imageAlt={img?.alt}
            isThumbnail={false}
            inDrawer={false}
            display="block"
            width="full"
            height="auto"
            style={{maxHeight: 160, objectFit: 'contain'}}
          />
        </Box>

        <Stack
          align={[isPLPTile ? 'flex-start' : 'center', 'center']}
          flex="1"
          paddingLeft={[isPLPTile ? '12px' : '0px', '0px']}
          marginTop={['0px', '20px']}
        >
          <Text
            as={Link}
            href={productLink}
            variant="text4"
            textAlign={["left", "center"]}
            flex="1"
            style={{flexBasis: '4.5rem'}}
            flexGrow="0"
            overflow="hidden"
            color={{default: 'gray800', hover: 'gray700'}}
            marginTop={['32px', '0px']}
            lineHeight="short"
            data-test-selector="product-list-item-name"
            onClick={() => {
              sendViewProduct({product: {id: product.id}})
            }}
          >
            {product.name}
          </Text>

          <Box>
            {product.productRating ? (
              <ReviewStars rating={product.productRating} />
            ) : (
              <Box height="20px" display={['none', 'block']} />
            )}
            <Price
              price={displayPrice}
              promotionPrice={promotionPrice}
              priceInfo={product.priceInfo}
              isPLPTile={isPLPTile}
              spacing="12px"
            />
            {nearPromo && (
              <Flex justify="center">
                <MultiBuyPromoOfferMessage
                  promotion={nearPromo}
                  productId={product.id}
                />
              </Flex>
            )}
            <Stack spacing="4px" marginTop="8px">
              {product.pdpBadgeAsset && (
                <Stack style={{maxWidth: 137}} align="center">
                  <PDPBadgeAsset badgeKey={product.pdpBadgeAsset} />
                </Stack>
              )}
              {multiBuyPromo && (
                <MultiBuyPromo promotion={multiBuyPromo} />
              )}
            </Stack>

            {nearPromo && !hideOffer ? (
              <NearPromo promotion={nearPromo} />
            ) : null}
          </Box>
        </Stack>

        <Box
          width={[isPLPTile ? 'full' : 'auto', 'auto']}
          display={[isPLPTile ? 'flex' : 'block', 'block']}
          justifyContent="flex-end"
        >
          {product.isAddToCartDisabled ? (
            <>
              <Box display={['none', 'block']}>
                <Button disabled variant="secondary" marginTop="16px" style={{width: 150}}>
                  {product.disabledStatus || 'Unavailable'}
                </Button>
              </Box>
              <Box display={['block', 'none']}>
                <Button disabled variant="secondary" marginTop="12px">
                  {product.disabledStatus || 'Unavailable'}
                </Button>
              </Box>
            </>
          ) : (
            <UpdateCartItemQuantity
              product={hit}
              quantity={clickedQuantity || 0}
              setQuantity={setClickedQuantity}
              maxQuantity={product.maxOrderQuantity}
              cartLocation={CART_LOCATION.PROMO}
              renderButton={
                <ATBButtonPopover
                  isPLPTile={isPLPTile}
                  hit={hit}
                  onButtonClick={(e) => {
                    addToBasket(e)
                    sendAddToCartData(hit, CART_LOCATION.PROMO)
                  }}
                  displayPrice={displayPrice}
                  data-test-selector="plp-add-to-basket-button"
                  style={{width: !isPLPTile ? 150 : 70}}
                  marginTop={!isPLPTile ? '16px' : '0px'}
                  additionalTextProps={{style: {pointerEvents: 'none'}}}
                />
              }
            />
          )}
        </Box>
      </Flex>
    </Box>
  )
}

const ProductTile = observer(ProductTileContainer)

const ProductTileRegularContainer: React.FC<ProductTileRegularProps> = ({
  productId,
  showAddToBasket,
  hideOffer,
  isPLPTile
}) => {
  const productStore = useProductStore()
  const product = productStore.productsById[productId]

  if (product == null) {
    return null
  }

  return <ProductTile product={product} showAddToBasket={showAddToBasket} hideOffer={hideOffer} isPLPTile={true}/>
}

export const ProductTileRegular = observer(ProductTileRegularContainer)

interface ProductTileRegularProps {
  className?: string
  isPLPTile?: boolean
  hideOffer?: boolean
  productId: string
  showAddToBasket?: boolean
}
