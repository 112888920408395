import {useEffect} from 'react'
import {useGTMDispatch} from '../analytics/react-gtm-hook/index'
import {useCustomerStore} from '../store/hooks/useStore'
import {useBasketStore} from '../store/hooks/useStore'
import {useSoftLogoutData} from '../hooks/use-soft-logout-data'
import { getDeviceType } from '../utils/utils'

/**
 * User data tracking hook, watches changes to customer object and 
 * updates data layer in response
 * @returns 
 */
export const useUserDataTracking = () => {

  const {customerInfo, status } = useCustomerStore()
  const sendDataToGTM = useGTMDispatch()
  const {deliveryPostalCode, currentDeliveryStoreId} = useBasketStore()
  const softLogoutData = useSoftLogoutData() 
  
  useEffect(() => {

    if(status != 'done') return

    setTimeout(() => {

      try {

        const userData = createUserData(
        {
            customerInfo: customerInfo, 
            postCode: deliveryPostalCode,
            storeId: currentDeliveryStoreId
        },
        softLogoutData
        )

        sendDataToGTM(userData)

      } catch (error) { 
          console.error('useUserDataTracking userEffect error: ' + error.message)
      }

    }, 0)

  }, [customerInfo, status, deliveryPostalCode, currentDeliveryStoreId])

  return null
}

/**
 * 
 * @param {*} sourceData Source customer info from Customer store
 * @param {*} softLogoutData Soft logout data to fall back to 
 * @returns 
 */
const createUserData = (sourceData, softLogoutData) => { 

  const {customerInfo, postCode, storeId } = sourceData  

  let softUserData = {}
  const isSoftLogout = softLogoutData.softLogout

  if(isSoftLogout ) {

    // Simplified object used to calculate custom status
    const softCustomerOrderStatistics = {
      authType: 'registered',
      c_ordersCount: softLogoutData.orderCount,
      c_legacyOrderCount: softLogoutData.legacyOrderCount 
    }

    softUserData = {
      loggedIn: 'Soft logged in', 
      firstName: softLogoutData.firstName,
      email: softLogoutData.email,
      phone: preferredAddressPhone(softLogoutData.addressBook) ?? softLogoutData.phone,
      registered: 'Yes',
      lastName: softLogoutData.lastName,
      addressBook: redactAddressBook(softLogoutData.addressBook),
      userId: softLogoutData.customerNo,
      customerId: softLogoutData.customerNo,
      orderCount: softLogoutData.orderCount,
      customerStatus: calculateCustomerStatus(softCustomerOrderStatistics)
    }
  }

  const customerId = (customerInfo?.customerNo == undefined) 
    ? customerInfo.customerId 
    : customerInfo.customerNo

  const userData = {
    'event' :'userData',
    customerId: customerId,  
    userId: customerInfo.customerNo,
    loggedIn: customerInfo.authType === 'registered' ? 'Logged In' : 'Logged Out',
    registered: customerInfo.authType === 'registered' ? 'Yes' : 'No',
    firstName: customerInfo.firstName,
    lastName: customerInfo.lastName,
    email: customerInfo.login,
    phone: preferredAddressPhone(customerInfo.addresses) ?? customerInfo.phoneHome,
    postCode: redactPostcode(postCode),
    storeID: storeId,
    deviceType: getDeviceType(window?.navigator?.userAgent),
    orderCount: customerInfo.c_ordersCount,
    customerStatus: calculateCustomerStatus(customerInfo),
    addressBook: redactAddressBook(customerInfo.addresses)
  }

  return {...userData, ...softUserData}
}

/**
 * Redact a postcode down to it's prefix
 * @param postCode postcode to evaluate
 * @returns First part of postcode
 */
const redactPostcode = (postCode) => { 
  return (postCode?.trim().length > 0) ? postCode.split(' ')[0] : postCode
}



/**
 * Calculate a customer status classification
 * @param {*} customerInfo 
 * @returns String status description of customer
 */
const calculateCustomerStatus = (customerInfo) => {
 
  if(customerInfo.authType != 'registered') return 'unknown'

  const orderCount = (customerInfo?.c_ordersCount != undefined) ? customerInfo?.c_ordersCount : 0
  const legacyOrderCount = (customerInfo?.c_legacyOrderCount != undefined) ? customerInfo?.c_legacyOrderCount : 0   

  return (orderCount > 0 || legacyOrderCount > 0) ? 'existing' : 'new'

}

/**
 * Parse and address book and return shorthand addresses
 * @param {*} addresses address book to the evaluate
 * @returns array of array of shortened customer address objects
 */
const redactAddressBook = (addresses) => { 

  if(!addresses?.length >0) return undefined

  const addressBook = []
  
  for(let address of addresses) {
    addressBook.push({
      'address1': address.address1,
      'address2': address.address2,
      'city': address.city,
    })
  }

  return addressBook
}

/**
 * Parse address book and return phone number from preferred (primary) address
 * @param {*} addresses address book to evaluate
 * @returns the phone number from preferred address
 */
const preferredAddressPhone = (addresses) => { 

  if (!addresses?.length > 0) return undefined

  let preferredPhone
  
  for (let address of addresses) {
    if (address.preferred) {
      preferredPhone = address.phone
      break
    }
  }

  return preferredPhone
}