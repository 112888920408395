import {observer} from 'mobx-react-lite'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import React, {useEffect, useState} from 'react'
import {Layout} from '../../components/layout'
import {BaseButton, Box, Container, Flex, Stack, Text} from '../../vanilla'
import {MealDealItem} from './meal-deals-item'
import {useMealDeals} from './use-meal-deals'
import {Link} from 'react-router-dom'
import {useMealDealsCompleteness} from './use-meal-deal-completeness'
import {CompletenessMessage} from './completeness-message'
import {
  stickyBannerContainer,
  scaleDownFadeOutKeyFrame,
  scaleUpFadeInKeyFrame,
} from '../../components/cms/sticky-banner/styles.css'
import {useGlobalStore, useProductStore} from '../../store/hooks/useStore'
import { useContentStore } from '../../store/hooks/useStore'
import { Slot } from '../../components/cms/Slot'

const animationMap = {
  show: scaleUpFadeInKeyFrame,
  hide: scaleDownFadeOutKeyFrame,
} as const

const MealDealsContainer: React.FC = () => {
  const {mealDeals, status} = useMealDeals()
  const {complete, update} = useMealDealsCompleteness(mealDeals?.groups)
  const [animation, setAnimation] = useState<keyof typeof animationMap>('show')
  const cms = useContentStore()
  const mealDealPromoKey = mealDeals?.id
  useEffect(() => {
    if (complete) {
      setAnimation('show')
    } else {
      setAnimation('hide')
    }
  }, [complete])

  useEffect(() => {
    (async function () {
      if (mealDealPromoKey && !cms.contentById[mealDealPromoKey]) {
        await cms.fetchItemByKey(mealDealPromoKey)
      }
    })()
  }, [mealDealPromoKey, cms])


  if (['initial', 'loading'].includes(status)) {
    return null
  }
  const redirectShoppingUrl ='/offers'

  const {selectedPriceDayOffset, selectedRegion} = useGlobalStore()
  const {productsById} = useProductStore()

  const filteredProductsIds = (productsIds: string[]) => 
    productsIds.filter(id => {
    const product = productsById[id]
    return product && !(
      (selectedPriceDayOffset != null && product.matrixPrices?.[selectedPriceDayOffset]?.[selectedRegion] == null)
      || product.isAddToCartDisabled
    ) 
  })

  const isDealNotAvailable = mealDeals?.groups?.some(group => {
    const filteredIds = filteredProductsIds(group.ids)
    return filteredIds.length < parseInt(group.req)
  })
  
  if ((mealDeals == null && status === 'done') || mealDeals?.c_isMealDealLanding !== true || isDealNotAvailable) {
    return (
      <Layout>
        <Container paddingX={['0px', '8px']} paddingY="16px">
          <Flex justifyContent="center">
            <Box bg="white" padding="32px" maxWidth="half">
              <Stack gap="16px">
                <Text variant="heading4">Deal Not Available</Text>
                <Text variant="text4">Sorry, this deal is no longer available.</Text>
                <Text variant="text4">
                  Don't worry, we have loads of other great deals available in your area!
                </Text>
                <BaseButton as={Link }to={redirectShoppingUrl}>
                  <Text variant="text4">See Other Deals</Text>
                </BaseButton>
              </Stack>
            </Box>
          </Flex>
        </Container>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <Container paddingX={['0px', '8px']} paddingY="16px">
        <Box display={'block'} paddingBottom={'20px'}>
    {cms.contentById[mealDealPromoKey] && (
      <Slot
        data={cms.contentById[mealDealPromoKey]}
        deliveryKey={cms.contentById[mealDealPromoKey]._meta.deliveryKey}
      />
    )}
    </Box>
          <Stack gap="48px" id={mealDeals.id}>
            {complete ? <CompletenessMessage /> : null}
            {mealDeals.c_contentAsset ? (
              <Box as="img" width="full" src={getAssetUrl(mealDeals.c_contentAsset)} />
            ) : null}
            {mealDeals.groups?.map?.((group, index) => (
              <MealDealItem
                index={index}
                key={`${mealDeals.id}-${group.label}`}
                label={group.label}
                productsIds={filteredProductsIds(group.ids)}
                req={group.req}
                setCompleteness={(complete) => {
                  update(index, complete)
                }}
              />
            ))}
            <Box>
              <Flex gap="16px" justifyContent="center">
                <BaseButton to="/basket" as={Link} variant="outlineDark">
                  <Text>View Basket</Text>
                </BaseButton>
                <BaseButton as={Link} to="/">
                  <Text>Continue Shopping</Text>
                </BaseButton>
              </Flex>
            </Box>
            {mealDeals.calloutMsg ? (
              <Box>
                <Text color="gray500">{mealDeals.calloutMsg}</Text>
              </Box>
            ) : null}
          </Stack>
        </Container>
      </Layout>
      {complete ? (
        <Box
          className={stickyBannerContainer}
          position="fixed"
          zIndex="sticky"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            animationName: animationMap[animation],
          }}
        >
          <CompletenessMessage
            onClose={() => {
              setAnimation('hide')
            }}
            showCloseIcon
          />
        </Box>
      ) : null}
    </>
  )
}

export const MealDeals = observer(MealDealsContainer)
