import React, {useMemo, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {observer} from 'mobx-react-lite'
import {ReactElement} from 'react-markdown/lib/react-markdown'
import {Box, BoxProps, Flex, Stack, Text, useDisclosure} from '../../../vanilla'
import {Promotion} from '../../../store/ProductStore'
import {usePromotion} from '../../../store/hooks/usePromotion'
import {useBasketStore, useGlobalStore} from '../../../store/hooks/useStore'
import {CheckIcon, MultibuyBadgeIcon, PlusIcon} from '../../icons'
import useNavigation from '../../../hooks/use-navigation'
import {PromotionModal} from './promotion-modal'
import {nearPromoContainer} from './styles.css'
import {useBookDeliveryPopover} from '../../../contexts'
import { ModalNavigationProps } from '../../../types/ui'

interface MultiBuyPromoOfferMessageProps {
  promotion: Promotion
  productId?: string
  productIds?: string[]
  isPromoModal?: boolean
}

export const MultiBuyPromoOfferMessage = observer((props: MultiBuyPromoOfferMessageProps) => {
  const {promotion, productId, productIds, isPromoModal} = props

  const promo = usePromotion(promotion.ID)
  const basketStore = useBasketStore()
  if (!promo) return null
  if (!basketStore.missedOffers?.promotions && !basketStore.missedOffers?.completedPromos) return null

  // Grab the right information/promotion for the nearness messaging
  const matchedPromotion = basketStore.missedOffers.promotions[promotion.ID]
  // Grab the current completed promotions
  const completedPromotion = basketStore.missedOffers.completedPromos?.[promotion.ID]

  // Total number of items in the basket relevant to the promotion
  const totalPromotionItemsQuantity =
    basketStore?.basket?.productItems
      ?.filter(({productId}) => productId && promo?.productIds?.includes(productId))
      ?.reduce((total, {quantity}) => (total += quantity || 0), 0) || 0

  // Items in the basket that are relevant to the promotion
  const getMatchingCartProducts = (() => {
    if (productIds) {
      const productIdArray = []
      for (const productId of productIds) {
        const productInBasket = basketStore?.basket?.productItems?.find(x => x.productId == productId)
        if (productInBasket) {
          productIdArray.push(productId)
        }
      }
      return productIdArray
    } else {
      return basketStore?.basket?.productItems?.find(
        (cartProduct) => cartProduct.productId === productId,
      )
    }
  })()

// Boolean check on whether the selected item/items are in the basket (always true for promo modals)
let isProductInBasket
if (isPromoModal) {
  isProductInBasket = true
} else {
  isProductInBasket = Array.isArray(getMatchingCartProducts) && getMatchingCartProducts?.length || !Array.isArray(getMatchingCartProducts) && getMatchingCartProducts != null
}

// Check to see if you've reached the promotion discount (ignore isProductInBasket for promo modals)
let reachedPromotionDiscount
if (isPromoModal) {
  reachedPromotionDiscount = completedPromotion || matchedPromotion?.remainingQty === 0
} else {
  reachedPromotionDiscount = isProductInBasket && (completedPromotion || matchedPromotion?.remainingQty === 0)
} 

// Creates the nearness message
  const getOfferMessage = () => {
    if (!totalPromotionItemsQuantity && (!matchedPromotion || !completedPromotion)) return ''

    if ((matchedPromotion || completedPromotion) && totalPromotionItemsQuantity) {
      if (completedPromotion || matchedPromotion?.remainingQty === 0) return 'You got it'
      return `${matchedPromotion.remainingQty} more to get it ${
        matchedPromotion.exceededFirstCycle ? 'again' : ''
      }`
    }
  }
  if (!matchedPromotion && !completedPromotion) return null
  return !matchedPromotion?.removeNearnessMessage && isProductInBasket ? (
    <Flex align="center" gap="2px" marginY="4px">
      {reachedPromotionDiscount ? (
        <CheckIcon color="accent1" boxSize="12px" />
      ) : (
        <PlusIcon color="promotionColor" boxSize="12px" />
      )}
      <Text
        variant="text1"
        lineHeight="none"
        color={reachedPromotionDiscount ? 'accent1' : 'promotionColor'}
      >
        {getOfferMessage()}
      </Text>
    </Flex>
  ) : null
})

interface MultiBuyPromoProps {
  promotion: Promotion
}

export const MultiBuyPromo = observer((props: MultiBuyPromoProps) => {
  const {promotion} = props

  const {formatNumber} = useIntl()
  const {currency, customSitePreferences} = useGlobalStore()

  const showMultiBuyBadge = customSitePreferences.showMultibuyPriceBadge || false
  const unitPrice = Number(promotion?.multibuyUnitPrice)

  const getMultiBuyPrice = () => {
    if (isNaN(unitPrice) || !showMultiBuyBadge) {
      return null
    }

    const isPences = unitPrice < 1

    if (isPences) {
      return `${(unitPrice * 100).toFixed(0)}p`
    }

    return formatNumber(unitPrice, {style: 'currency', currency})
  }

  const price = useMemo(() => getMultiBuyPrice(), [unitPrice, showMultiBuyBadge, currency])

  if (!showMultiBuyBadge || isNaN(unitPrice)) return null

  return (
    <Flex
      style={{width: 150, backgroundColor: '#ffea00'}}
      paddingLeft="36px"
      paddingRight="4px"
      paddingY="4px"
      position="relative"
      borderRadius="base"
      align="center"
      justifyContent="center"
    >
      <MultibuyBadgeIcon boxSize="32px" position="absolute" style={{top: -3, left: 2}} />
      <Flex gap="4px" align="flex-end">
        <Text as="span" variant="text0" color="black" lineHeight="none" marginBottom="2px">
          from
        </Text>
        <Text as="span" variant="heading1Bold" color="black" lineHeight="none">
          {price}
        </Text>
        <Text as="span" variant="text0" color="black" lineHeight="none" marginBottom="2px">
          each
        </Text>
      </Flex>
    </Flex>
  )
})

interface NearPromoProps extends BoxProps<'div'> {
  promotion: Promotion
  showViewMore?: boolean
  alwaysInline?: boolean
  children?: ReactElement
  onNavigate?: (url: string) => void
  search?: boolean
  cartDrawer?: boolean
  modalNavigationProps?: ModalNavigationProps
}

export const NearPromo = observer((props: NearPromoProps) => {
  const {
    promotion,
    showViewMore = true,
    alwaysInline = false,
    children,
    onNavigate,
    search,
    cartDrawer,
    modalNavigationProps,
    ...rest
  } = props
  const promotionModal = useDisclosure(false)
  const {getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences} = useGlobalStore()
  const [mixAndMatchText, setMixAndMatchText] = useState(false)
  const basketStore = useBasketStore()
  const {showBookDeliveryPopover} = useBookDeliveryPopover()
  const navigate = typeof onNavigate === 'function' ? onNavigate : useNavigation()

  const promo = usePromotion(promotion.ID)

  const isMixAndMatch =
    promotion.t === 'BuyXForTotal' && promo?.productIds && promo?.productIds?.length > 1
  const isMealDealLanding = promo?.c_isMealDealLanding
  const mealDealUrl = isMealDealLanding ? encodeURI(`/meal-deals?promo=${promotion.ID}`) : ''

  const splitOfferMessage = () => {
    const [amount, price] = promotion.msg.split('for')
    if (search || cartDrawer) {
      return <Text variant="text2Bold" textAlign="center">{amount} for {price}</Text>
    }
    return (
      <Text variant="text5" textAlign="center" lineHeight="none" marginBottom="2px">{amount} <span style={{fontWeight: '500', fontSize: '11px'}}>for</span> {price}</Text>
    )
  }

  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('showMixandMatchTextOnMultibuyBadge')) {
        await getCustomSitePreferenceById('showMixandMatchTextOnMultibuyBadge')
      }
      setMixAndMatchText(customSitePreferences['showMixandMatchTextOnMultibuyBadge'] as boolean)
    }())
  })

  const isBasket = location.pathname === '/basket'

  return promo ? (
    <>
      <Box
        color="black"
        cursor="pointer"
        display="flex"
        flexDirection="column"
        position="absolute"
        borderRadius="base"
        paddingY="4px"
        width="full"
        alignItems="center"
        height={['auto', alwaysInline ? 'auto' : '80px']}
        className={children ? '' : nearPromoContainer}
        onClick={(e: React.MouseEvent | React.TouchEvent) => {
          if (!basketStore.canAddItems){
            if (window.innerWidth > 768) {
              window.scrollTo({top: 0})
            }
            showBookDeliveryPopover()
          } else if (isMealDealLanding) {
            navigate(mealDealUrl)
          } else {
            e.preventDefault()
            promotionModal.onOpen()
          }
        }}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <Stack
              gap={search || cartDrawer ? '8px' : ['8px', '0px']}
              direction={search || cartDrawer ? 'row' : ['row', 'column']}
              height={["16px", isBasket && isMixAndMatch && !search ? '28px' : alwaysInline || isMixAndMatch ? '16px' : '44px']}
              justifyItems='center'
            >
              <Text
                display="flex"
                alignItems="center"
                align="center"
                variant={search || cartDrawer ? "text2Bold" : "text5"}
                fontSize="inherit"
                fontWeight="inherit"
                lineHeight="inherit"
              >
                {(promotion && promotion.msg ? (promotion.msg.includes('for') ? splitOfferMessage() : promotion.msg) : 'Offer')}
              </Text>
              {isMixAndMatch && mixAndMatchText && (
                <Text
                  style={{fontSize: search ? "12px" : '11px', fontWeight: '500'}}
                  display="flex"
                  alignItems="center"
                  margin={search || cartDrawer ? "0px" : 'auto'}
                  align="center"
                  variant="unstyled"
                  lineHeight="none"
                >
                  Mix & Match
                </Text>
              )}
            </Stack>
            {showViewMore && (
              <Text
                variant="unstyled"
                fontSize="xs"
                fontWeight="light"
                align="center"
                paddingTop="4px"
                display={['none', 'block']}
                textDecoration="underline"
                position="absolute"
                bottom="0px"
              >
                View offer
              </Text>
            )}
          </>
        )}
      </Box>

      {promotionModal.isOpen && <PromotionModal promo={promo} {...promotionModal} promotion={promotion} modalNavigationProps={modalNavigationProps}/>
      }
    </>
  ) : null
})
